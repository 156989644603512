<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-10-04 15:52:06
 * @ Description: Component displaying the left app drawer in the dashboard section.
 -->

<template>
    <!-- Desktop -->
    <v-navigation-drawer
        v-model="menuDrawer"
        disable-resize-watcher
        color="white"
        style="border-right: 5px solid white;"
        width="235"
        app
        v-if="$vuetify.breakpoint.lgAndUp"
        class="no-print"
    >
        <div
            style="background-color: rgba(255, 168, 88, 0.1); max-height: 100vh; height: 100vh; overflow-y: auto;"
        >
            <!-- Logo -->
            <v-list-item
                class="pt-3"
            >
                <v-img
                    src="/branding/FixerLogoBlue.png"
                    class="mx-auto"
                    height="40px"
                    contain
                />
            </v-list-item>
            <!-- Logo -->

            <v-list-item>
                <v-divider
                    light
                    style="border-color: white;"
                />
            </v-list-item>

            <!-- Drawer Items -->
            <div
                v-for="(item, index) in drawerItems"
                :key="index"
            >
                <!-- Item with sub-items -->
                <v-list-group
                    v-if="item.children && hasPermission(item)"
                    color="#FFA858"
                    style="position: relative;"
                    @click="changeMenuItemSelection(index)"
                >
                    <template v-slot:activator>
                        <div
                            style="position: absolute; background-color: #FFA858; width: 4px; height: 100%; margin-left: -16px;"
                            v-show="item.value"
                        />
                        <v-list-item-content>
                            <v-list-item-title
                                style="display: flex; align-items: center; color: #2E567A; width: 100%;"
                                class="os-14-r"
                            >
                                <v-icon
                                    color="#2E567A"
                                    class="mr-1"
                                    size="24"
                                >
                                    {{ item.icon }}
                                </v-icon>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <div
                        v-for="(child, childIndex) in item.children"
                        :key="childIndex"
                    >
                        <v-list-item
                            v-if="!child.children"
                            color="#FFA858"
                            style="position: relative;"
                            @click="changeMenuSubItemSelection(index, childIndex)"
                        >
                            <v-list-item-content
                                style="margin-left: 32px;"
                            >
                                <v-list-item-title
                                    style="display: flex; align-items: center; color: #2E567A; width: 100%; margin-left: 20px;"
                                    class="os-14-r"
                                >
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group
                            v-else
                            sub-group
                            @click="changeMenuSubItemSelection(index, childIndex)"
                            :key="child.id"
                            color="#FFA858"
                            v-model="child.expanded"
                        >
                            <template v-slot:activator>
                                <v-list-item-content
                                    style="margin-left: -36px;"
                                >
                                    <v-list-item-title 
                                        style="display: flex; align-items: center; color: #2E567A; width: 100%;"
                                        class="os-14-r"
                                    >
                                        <v-icon
                                            v-if="!child.expanded"
                                        >
                                            {{ mdiChevronDown }}
                                        </v-icon>
                                        <v-icon
                                            v-else
                                        >
                                            {{ mdiChevronUp }}
                                        </v-icon>
                                        <span style="margin-left: 8px;">{{ child.text }}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <div
                                v-for="(child2, childIndex2) in child.children"
                                :key="childIndex2"
                            >
                                <v-list-item
                                    v-if="!child2.children"
                                    color="#FFA858"
                                    @click="changeMenuSubItemSelection(index, childIndex, childIndex2)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title
                                            style="display: flex; align-items: center; color: #2E567A; width: 100%; margin-left: 40px;"
                                            class="os-12-r"
                                        >
                                            {{ child2.text }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-list-group>
                    </div>
                </v-list-group>
                <!-- Item with sub-items -->

                <!-- Item without sub-items -->
                <v-list-item
                    v-else-if="hasPermission(item)"
                    color="#FFA858"
                    style="position: relative;"
                    @click="changeMenuItemSelection(index)"
                >
                    <div
                        style="position: absolute; background-color: #FFA858; width: 4px; height: 100%; margin-left: -16px;"
                        v-show="item.value"
                    />
                    <v-list-item-content>
                        <v-list-item-title
                            style="display: flex; align-items: center; color: #2E567A; width: 100%;"
                            class="os-14-r"
                        >
                            <v-icon
                                color="#2E567A"
                                class="mr-1"
                                size="24"
                            >
                                {{ item.icon }}
                            </v-icon>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- Item without sub-items -->
            </div>
            <!-- Drawer Items -->

            <v-list-item
                v-if="$store.state.user.userData.subscription_id === 1 || $store.state.user.userData.subscription_id === 5"
            >
                <v-divider
                    light
                    style="border-color: white;"
                />
            </v-list-item>
            <v-list-item
                v-if="$store.state.user.userData.subscription_id === 1 || $store.state.user.userData.subscription_id === 5"
            >
                <div
                    style="color: #2E567A; width: 100%;"
                    class="text-center os-14-r"
                >
                    Your <span class="os-14-sb">7-DAY FREE TRIAL</span><br />
                    is active. <br /><br />
                    <span @click="packages" style="text-decoration: underline;" class="os-14-sb">Upgrade</span> to any of our Fixer packages and keep enjoying the benefits!
                </div>
            </v-list-item>

            <!-- Profile Completion Stats -->
            <v-list-item
                v-if="$store.state.user.userProfileCompletionStats.percentage < 100 && !isAdmin"
            >
                <v-divider
                    light
                    style="border-color: white;"
                />
            </v-list-item>
            <v-list-item
                v-if="$store.state.user.userProfileCompletionStats.percentage < 100 && !isAdmin"
            >
                <v-progress-circular
                    :size="110"
                    :width="10"
                    color="#FFA858"
                    :value="$store.state.user.userProfileCompletionStats.percentage"
                    rotate="270"
                    class="mx-auto"
                >
                    <span
                        style="color: #2E567A;"
                        class="os-14-r"
                    >
                        {{ $store.state.user.userProfileCompletionStats.percentage }}%
                    </span>
                </v-progress-circular>
            </v-list-item>
            <v-list-item
                class="pt-4"
                v-if="$store.state.user.userProfileCompletionStats.percentage < 100 && !isAdmin"
            >
                <div
                    style="color: #2E567A; width: 100%;"
                    class="text-center os-14-r"
                >
                    Please Complete <br />
                    Your Profile
                </div>
            </v-list-item>
            <!-- Profile Completion Stats -->
        </div>
    </v-navigation-drawer>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-navigation-drawer
        v-model="menuDrawer"
        disable-resize-watcher
        color="white"
        :width="$vuetify.breakpoint.smAndUp ? '35%' : '100%'"
        app
        v-else-if="$vuetify.breakpoint.mdAndDown"
    >
        <div
            style="background-color: rgba(255, 168, 88, 0.1); max-height: 100vh; height: 100vh; overflow-y: auto;"
        >
            <!-- Logo & Toggle Left Drawer Button -->
            <v-list-item
                class="pt-3"
            >
                <v-row>
                    <v-col
                        class="flex-grow-0 flex-shrink-1"
                    >
                        <v-img
                            src="/branding/FixerLogoBlue.png"
                            class="ml-auto"
                            height="40px"
                            contain
                        />
                    </v-col>
                    <v-col
                        class="flex-grow-1 flex-shrink-0 text-right"
                    >
                        <v-btn
                            icon
                            @click="toggleMenuDrawer"
                        >
                            <v-icon
                                color="#2E567A"
                            >
                                {{ mdiClose }}
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item>
            <!-- Logo & Toggle Left Drawer Button -->

            <v-list-item>
                <v-divider
                    light
                    style="border-color: white;"
                />
            </v-list-item>

            <!-- Drawer Items -->
            <div
                v-for="(item, index) in drawerItems"
                :key="index"
            >
                <!-- Items with sub-items -->
                <v-list-group
                    v-if="item.children && hasPermission(item)"
                    color="#FFA858"
                    style="position: relative;"
                    @click="changeMenuItemSelection(index)"
                >
                    <template v-slot:activator>
                        <div
                            style="position: absolute; background-color: #FFA858; width: 4px; height: 100%; margin-left: -16px;"
                            v-show="item.value"
                        />
                        <v-list-item-content>
                            <v-list-item-title
                                style="display: flex; align-items: center; color: #2E567A; width: 100%;"
                                class="os-14-r"
                            >
                                <v-icon
                                    color="#2E567A"
                                    class="mr-1"
                                    size="24"
                                >
                                    {{ item.icon }}
                                </v-icon>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <div
                        v-for="(child, childIndex) in item.children"
                        :key="childIndex"
                    >
                        <v-list-item
                            v-if="!child.children"
                            color="#FFA858"
                            style="position: relative;"
                            @click="changeMenuSubItemSelection(index, childIndex)"
                        >
                            <v-list-item-content
                                style="margin-left: 32px;"
                            >
                                <v-list-item-title
                                    style="display: flex; align-items: center; color: #2E567A; width: 100%; margin-left: 20px;"
                                    class="os-14-r"
                                >
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group
                            v-else
                            sub-group
                            @click="changeMenuSubItemSelection(index, childIndex)"
                            :key="child.id"
                            color="#FFA858"
                            v-model="child.expanded"
                        >
                            <template v-slot:activator>
                                <v-list-item-content
                                    style="margin-left: -36px;"
                                >
                                    <v-list-item-title 
                                        style="display: flex; align-items: center; color: #2E567A; width: 100%;"
                                        class="os-14-r"
                                    >
                                        <v-icon
                                            v-if="!child.expanded"
                                        >
                                            {{ mdiChevronDown }}
                                        </v-icon>
                                        <v-icon
                                            v-else
                                        >
                                            {{ mdiChevronUp }}
                                        </v-icon>
                                        <span style="margin-left: 8px;">{{ child.text }}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <div
                                v-for="(child2, childIndex2) in child.children"
                                :key="childIndex2"
                            >
                                <v-list-item
                                    v-if="!child2.children"
                                    color="#FFA858"
                                    @click="changeMenuSubItemSelection(index, childIndex, childIndex2)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title
                                            style="display: flex; align-items: center; color: #2E567A; width: 100%; margin-left: 40px;"
                                            class="os-12-r"
                                        >
                                            {{ child2.text }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-list-group>
                    </div>
                </v-list-group>
                <!-- Items with sub-items -->

                <!-- Items without sub-items -->
                <v-list-item
                    v-else-if="hasPermission(item)"
                    color="#FFA858"
                    style="position: relative;"
                    @click="changeMenuItemSelection(index)"
                >
                    <div
                        style="position: absolute; background-color: #FFA858; width: 4px; height: 100%; margin-left: -16px;"
                        v-show="item.value"
                    />
                    <v-list-item-content>
                        <v-list-item-title
                            style="display: flex; align-items: center; color: #2E567A; width: 100%;"
                            class="os-14-r"
                        >
                            <v-icon
                                color="#2E567A"
                                class="mr-1"
                                size="24"
                            >
                                {{ item.icon }}
                            </v-icon>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- Items without sub-items -->
            </div>
            <!-- Drawer Items -->

            <v-list-item
                v-if="$store.state.user.userData.subscription_id === 1 || $store.state.user.userData.subscription_id === 5"
            >
                <v-divider
                    light
                    style="border-color: white;"
                />
            </v-list-item>
            <v-list-item
                v-if="$store.state.user.userData.subscription_id === 1 || $store.state.user.userData.subscription_id === 5"
            >
                <div
                    style="color: #2E567A; width: 100%;"
                    class="text-center os-14-r"
                >
                    Your <span class="os-14-sb">7-DAY FREE TRIAL</span><br />
                    is active. <br /><br />
                    <span @click="packages" style="text-decoration: underline;" class="os-14-sb">Upgrade</span> to any of our Fixer packages and keep enjoying the benefits!
                </div>
            </v-list-item>

            <v-list-item>
                <v-divider
                    light
                    style="border-color: white;"
                />
            </v-list-item>
            <v-list-item>
                <v-row>
                    <!-- Profile Completion Stats -->
                    <v-col
                        cols="6"
                        class="text-center"
                        v-if="$store.state.user.userProfileCompletionStats.percentage < 100 && !isAdmin"
                    >
                        <v-progress-circular
                            :size="90"
                            :width="9"
                            color="#FFA858"
                            :value="$store.state.user.userProfileCompletionStats.percentage"
                            rotate="270"
                        >
                            <span
                                style="color: #2E567A;"
                                class="os-14-r"
                            >
                                {{ $store.state.user.userProfileCompletionStats.percentage }}%
                            </span>
                        </v-progress-circular>
                        <div
                            style="color: #2E567A; width: 100%;"
                            class="text-center os-14-r"
                        >
                            Please Complete <br />
                            Your Profile
                        </div>
                    </v-col>
                    <!-- Profile Completion Stats -->

                    <v-col
                        :cols="$store.state.user.userProfileCompletionStats.percentage < 100 ? 6 : 12"
                        class="text-center"
                        v-if="!isAdmin"
                    >
                        <!-- Calendar Button -->
                        <v-btn
                            icon
                            class="mx-auto"
                            aria-label="calendar"
                            large
                            @click="calendar"
                        >
                            <v-icon
                                color="#2E567A"
                                large
                            >
                                {{ mdiCalendar }}
                            </v-icon>
                        </v-btn>
                        <!-- Calendar Button -->

                        <br v-if="$store.state.user.userProfileCompletionStats.percentage < 100" />

                        <!-- Toggle Notification Drawer Button -->
                        <v-btn
                            icon
                            class="mx-auto"
                            aria-label="notifications"
                            large
                            @click="toggleMenuDrawerRight"
                        >
                            <v-badge
                                overlap
                                :content="notificationCount"
                                color="#FFA858"
                                v-if="notificationCount > 0"
                            >
                                <v-icon
                                    color="#2E567A"
                                    large
                                >
                                    {{ mdiBell }}
                                </v-icon>
                            </v-badge>
                            <v-icon
                                color="#2E567A"
                                large
                                v-else
                            >
                                {{ mdiBell }}
                            </v-icon>
                        </v-btn>
                        <!-- Toggle Notification Drawer Button -->
                    </v-col>
                </v-row>
            </v-list-item>
        </div>
    </v-navigation-drawer>
    <!-- Mobile -->
</template>
<script>
    import { GeneralProfileStatsController } from '@/logic/controller/index.controller.js'
    import { mdiHomeOutline, mdiFlashOutline, mdiAccountSupervisorOutline, mdiAccountDetailsOutline, mdiOrderBoolAscendingVariant, mdiHomeSwitchOutline, mdiDomain, mdiBriefcaseOutline, mdiNewspaperVariantOutline, /* , mdiPencilOutline, mdiLicense, */ mdiClose, mdiCalendar, mdiBell, mdiChevronDown, mdiChevronUp } from '@mdi/js'

    export default {
        name: 'NavigationDashboardDrawerLeftMenuComponent',

        props: {
            showMenuDrawer: {
                type: Boolean,
                default: false,
                required: true
            },

            notificationCount: {
                type: Number,
                default: 0,
                required: true
            }
        },

        watch: {
            showMenuDrawer: {
                handler (value) {
                    this.menuDrawer = value
                },
                immediate: true
            },

            menuDrawer: {
                handler (value) {
                    this.$emit('toggleMenuDrawer', value)
                }
            },

            '$route.path': {
                handler (value) {
                    // this.getDrawerSelection()
                    if (value.includes('/admin')) this.isAdmin = true
                    else this.isAdmin = false
                },
                immediate: true
                // deep: true
            }
        },

        computed: {
            drawerItems () {
                if (this.isAdmin) return this.adminDrawerItems
                else if (this.$store.state.user.userContext === 'company') return this.companyDrawerItems
                else return this.individualDrawerItems
            }
        },

        data () {
            return {
                mdiClose,
                mdiCalendar,
                mdiBell,
                mdiChevronDown,
                mdiChevronUp,
                menuDrawer: false,
                companyDrawerItems: [
                    {
                        text: 'Home',
                        icon: mdiHomeOutline,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Job',
                        icon: mdiFlashOutline,
                        value: false,
                        children: [
                            {
                                text: 'View Jobs',
                                value: false,
                                expanded: false,
                                children: [
                                    {
                                        text: 'All Jobs',
                                        value: false
                                    },
                                    {
                                        text: 'Active Jobs',
                                        value: false
                                    },
                                    {
                                        text: 'Pending Jobs',
                                        value: false
                                    },
                                    {
                                        text: 'Drafts',
                                        value: false
                                    }
                                ]
                            },
                            {
                                text: 'History',
                                value: false
                            },
                            {
                                text: 'Applicants',
                                value: false
                            }
                        ]
                    },
                    {
                        text: 'Professional Service',
                        icon: mdiAccountSupervisorOutline,
                        value: false,
                        children: [
                            {
                                text: 'Search Services',
                                value: false
                            },
                            {
                                text: 'Requested',
                                value: false,
                                expanded: false,
                                children: [
                                    // {
                                    //     text: 'Search',
                                    //     value: false
                                    // },
                                    {
                                        text: 'Active Services',
                                        value: false
                                    },
                                    {
                                        text: 'Pending Services',
                                        value: false
                                    },
                                    {
                                        text: 'History',
                                        value: false
                                    },
                                    // {
                                    //     text: 'Favourites',
                                    //     value: false
                                    // }
                                ]
                            },
                            {
                                text: 'Provided',
                                value: false,
                                expanded: false,
                                children: [
                                    // {
                                    //     text: 'All Services',
                                    //     value: false
                                    // },
                                    {
                                        text: 'Active Services',
                                        value: false
                                    },
                                    {
                                        text: 'Pending Services',
                                        value: false
                                    },
                                    {
                                        text: 'History',
                                        value: false
                                    }
                                ]
                            },
                            {
                                text: 'Favourites',
                                value: false
                            }
                        ]
                    },
                    {
                        text: 'My Info',
                        icon: mdiAccountDetailsOutline,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Administration',
                        icon: mdiOrderBoolAscendingVariant,
                        value: false,
                        children: null,
                        admin: true
                    }
                ],
                individualDrawerItems: [
                    {
                        text: 'Home',
                        icon: mdiHomeOutline,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Job',
                        icon: mdiFlashOutline,
                        value: false,
                        children: [
                            {
                                text: 'Search Jobs',
                                value: false
                            },
                            {
                                text: 'View Jobs',
                                value: false,
                                expanded: false,
                                children: [
                                    {
                                        text: 'Active Jobs',
                                        value: false
                                    },
                                    {
                                        text: 'Pending Jobs',
                                        value: false
                                    }
                                ]
                            },
                            {
                                text: 'History',
                                value: false
                            },
                            {
                                text: 'Favourites',
                                value: false
                            }
                        ]
                    },
                    {
                        text: 'Professional Service',
                        icon: mdiAccountSupervisorOutline,
                        value: false,
                        children: [
                            {
                                text: 'Search Services',
                                value: false
                            },
                            {
                                text: 'Requested',
                                value: false,
                                expanded: false,
                                children: [
                                    // {
                                    //     text: 'Search',
                                    //     value: false
                                    // },
                                    {
                                        text: 'Active Services',
                                        value: false
                                    },
                                    {
                                        text: 'Pending Services',
                                        value: false
                                    },
                                    {
                                        text: 'History',
                                        value: false
                                    },
                                    // {
                                    //     text: 'Favourites',
                                    //     value: false
                                    // }
                                ]
                            },
                            {
                                text: 'Provided',
                                value: false,
                                expanded: false,
                                children: [
                                    // {
                                    //     text: 'All Services',
                                    //     value: false
                                    // },
                                    {
                                        text: 'Active Services',
                                        value: false
                                    },
                                    {
                                        text: 'Pending Services',
                                        value: false
                                    },
                                    {
                                        text: 'History',
                                        value: false
                                    }
                                ]
                            },
                            {
                                text: 'Favourites',
                                value: false
                            }
                        ]
                    },
                    {
                        text: 'My Info',
                        icon: mdiAccountDetailsOutline,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Administration',
                        icon: mdiOrderBoolAscendingVariant,
                        value: false,
                        children: null,
                        admin: true
                    }
                ],
                adminDrawerItems: [
                    {
                        text: 'Home',
                        icon: mdiHomeOutline,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Manage Industries',
                        icon: mdiDomain,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Manage Job Titles',
                        icon: mdiBriefcaseOutline,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Manage News Feed',
                        icon: mdiNewspaperVariantOutline,
                        value: false,
                        children: null
                    },
                    {
                        text: 'Exit Administration',
                        icon: mdiHomeSwitchOutline,
                        value: false,
                        children: null
                    }
                ],
                generalProfileStatsController: null,
                isAdmin: false
            }
        },

        beforeMount () {
            this.generalProfileStatsController = new GeneralProfileStatsController()

            this.getDrawerSelection()
        },

        async mounted () {
            await this.getProfileCompletionStats()

            if (this.$vuetify.breakpoint.mdAndDown) this.menuDrawer = false
            else this.menuDrawer = true
        },

        methods: {
            async getProfileCompletionStats () {
                try {
                    const stats = await this.generalProfileStatsController.getCompletionStats()
                    this.$store.commit('user/setUserProfileCompletionStats', stats)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            hasPermission (menuItem) {
                if (!menuItem.admin) return true
                else {
                    if (this.$store.state.user.userData.is_admin) return true
                    else return false
                }
            },

            packages () {
                if (this.$route.path !== '/profile/account') this.$router.push('/profile/account')
            },

            calendar () {
                if (this.$route.path !== `/calendar`) this.$router.push(`/calendar`)
            },

            toggleMenuDrawer () {
                this.$emit('toggleMenuDrawer', false)
            },

            toggleMenuDrawerRight () {
                this.$emit('toggleMenuDrawerRight', true)
                this.toggleMenuDrawer()
            },

            changeMenuItemSelection (itemIndex) {
                this.resetCurrentMenuSelection()

                if (itemIndex !== null) {
                    const menuItems = this.drawerItems
                    menuItems[itemIndex].value = true

                    if (menuItems[itemIndex].text === 'Home' && !this.isAdmin && this.$route.path !== `/dashboard`) this.$router.push(`/dashboard`)
                    else if (menuItems[itemIndex].text === 'Home' && this.isAdmin && this.$route.path !== `/admin/dashboard`) this.$router.push(`/admin/dashboard`)
                    else if (menuItems[itemIndex].text === 'Administration' && this.$route.path !== `/admin/dashboard`) {
                        this.$router.push(`/admin/dashboard`)
                        this.isAdmin = true
                    } else if (menuItems[itemIndex].text === 'Exit Administration' && this.$route.path !== `/dashboard`) {
                        this.$router.push(`/dashboard`)
                        this.isAdmin = false
                    } else if (menuItems[itemIndex].text === 'Manage Industries' && this.$route.path !== `/admin/industries`) {
                        this.$router.push(`/admin/industries`)
                    } else if (menuItems[itemIndex].text === 'Manage Job Titles' && this.$route.path !== `/admin/job_titles`) {
                        this.$router.push(`/admin/job_titles`)
                    } else if (menuItems[itemIndex].text === 'Manage News Feed' && this.$route.path !== `/admin/news_feed`) {
                        this.$router.push(`/admin/news_feed`)
                    } else if (menuItems[itemIndex].text === 'My Info' && this.$route.path !== `/profile`) {
                        this.$router.push(`/profile`)
                    }
                }
            },

            changeMenuSubItemSelection (parentItemIndex, itemIndex, itemChildIndex, itemGrandchildIndex) {
                this.resetCurrentMenuSelection()

                const menuItems = this.drawerItems
                if (itemGrandchildIndex !== undefined && itemGrandchildIndex !== null) {
                    menuItems[parentItemIndex].value = true
                    menuItems[parentItemIndex].children[itemIndex].value = true
                    menuItems[parentItemIndex].children[itemIndex].children[itemChildIndex].value = true
                    menuItems[parentItemIndex].children[itemIndex].children[itemChildIndex].children[itemGrandchildIndex].value = true
                } else if (itemChildIndex !== undefined && itemChildIndex !== null) {
                    menuItems[parentItemIndex].value = true
                    menuItems[parentItemIndex].children[itemIndex].value = true
                    menuItems[parentItemIndex].children[itemIndex].children[itemChildIndex].value = true

                    const selectedItem = menuItems[parentItemIndex].children[itemIndex].children[itemChildIndex].text
                    const selectedItemParent = menuItems[parentItemIndex].children[itemIndex].text
                    const selectedItemGrandParent = menuItems[parentItemIndex].text

                    if (selectedItemGrandParent === 'Job') {
                        if (this.$store.state.user.userContext === 'company') {
                            switch (selectedItem) {
                            case 'All Jobs':
                                if (this.$route.path !== '/job/all') this.$router.push('/job/all')
                                break;
                            case 'Active Jobs':
                                if (this.$route.path !== '/job/active') this.$router.push('/job/active')
                                break;
                            case 'Pending Jobs':
                                if (this.$route.path !== '/job/pending') this.$router.push('/job/pending')
                                break;
                            case 'Drafts':
                                if (this.$route.path !== '/job/drafts') this.$router.push('/job/drafts')
                                break;
                            }
                        } else if (this.$store.state.user.userContext === 'individual') {
                            switch (selectedItem) {
                            case 'Search Jobs':
                                if (this.$route.path !== '/job/search') this.$router.push('/job/search')
                                break;
                            case 'Active Jobs':
                                if (this.$route.path !== '/job/active') this.$router.push('/job/active')
                                break;
                            case 'Pending Jobs':
                                if (this.$route.path !== '/job/pending') this.$router.push('/job/applications')
                                break;
                            case 'History':
                                if (this.$route.path !== '/job/history') this.$router.push('/job/history')
                                break;
                            case 'Favourites':
                                if (this.$route.path !== '/job/favourites') this.$router.push('/job/favourites')
                                break;
                            }
                        }
                    } else if (selectedItemGrandParent === 'Professional Service') {
                        if (selectedItemParent === 'Requested') {
                            switch (selectedItem) {
                            case 'Active Services':
                                if (this.$route.path !== '/service/requested/active') this.$router.push('/service/requested/active')
                                break;
                            case 'Pending Services':
                                if (this.$route.path !== '/service/requested/pending') this.$router.push('/service/requested/pending')
                                break;
                            case 'History':
                                if (this.$route.path !== '/service/requested/history') this.$router.push('/service/requested/history')
                                break;
                            }
                        } else if (selectedItemParent === 'Provided') {
                            switch (selectedItem) {
                            case 'Active Services':
                                if (this.$route.path !== '/service/provided/active') this.$router.push('/service/provided/active')
                                break;
                            case 'Pending Services':
                                if (this.$route.path !== '/service/provided/pending') this.$router.push('/service/provided/pending')
                                break;
                            case 'History':
                                if (this.$route.path !== '/service/provided/history') this.$router.push('/service/provided/history')
                                break;
                            }
                        }
                    }
                } else {
                    menuItems[parentItemIndex].value = true
                    menuItems[parentItemIndex].children[itemIndex].value = true

                    const selectedItem = menuItems[parentItemIndex].children[itemIndex].text
                    const selectedItemParent = menuItems[parentItemIndex].text
                    
                    if (selectedItemParent === 'Job') {
                        if (this.$store.state.user.userContext === 'company') {
                            switch (selectedItem) {
                            case 'History':
                                if (this.$route.path !== '/job/history') this.$router.push('/job/history')
                                break;
                            case 'Applicants':
                                if (this.$route.path !== '/job/applicants') this.$router.push('/job/applicants')
                                break;
                            }
                        } else if (this.$store.state.user.userContext === 'individual') {
                            switch (selectedItem) {
                            case 'Search Jobs':
                                if (this.$route.path !== '/job/search') this.$router.push('/job/search')
                                break;
                            case 'History':
                                if (this.$route.path !== '/job/history') this.$router.push('/job/history')
                                break;
                            case 'Favourites':
                                if (this.$route.path !== '/job/favourites') this.$router.push('/job/favourites')
                                break;
                            }
                        }
                    } else if (selectedItemParent === 'Professional Service') {
                        switch (selectedItem) {
                        case 'Search Services':
                            if (this.$route.path !== '/service/search') this.$router.push('/service/search')
                            break;
                        case 'Favourites':
                            if (this.$route.path !== '/service/favourites') this.$router.push('/service/favourites')
                            break;
                        }
                    }
                }
            },

            resetCurrentMenuSelection () {
                const menuItems = this.drawerItems
                const currentSelectedItem = menuItems.findIndex(item => item.value === true)

                if (currentSelectedItem > -1) {
                    if (menuItems[currentSelectedItem].children && menuItems[currentSelectedItem].children.length > 0) {
                        const menuItemChildren = menuItems[currentSelectedItem].children
                        const currentSelectedChildItem = menuItemChildren.findIndex(childItem => childItem.value === true)

                        if (currentSelectedChildItem > -1) {
                            if (menuItemChildren[currentSelectedChildItem].children && menuItemChildren[currentSelectedChildItem].children.length > 0) {
                                const menuItemGrandchildren = menuItemChildren[currentSelectedChildItem].children
                                const currentSelectedGrandchild = menuItemGrandchildren.findIndex(grandchildItem => grandchildItem.value === true)

                                if (currentSelectedGrandchild > -1) {
                                    if (menuItemGrandchildren[currentSelectedGrandchild].children && menuItemGrandchildren[currentSelectedGrandchild].children.length > 0) {
                                        const menuItemGreatGrandchildren = menuItemGrandchildren[currentSelectedGrandchild].children
                                        const currentSelectedGreatGrandchild = menuItemGreatGrandchildren.findIndex(greatGrandchildItem => greatGrandchildItem.value === true)

                                        if (currentSelectedGreatGrandchild > -1) {
                                            menuItemGreatGrandchildren[currentSelectedGreatGrandchild].value = false
                                        }
                                    }

                                    menuItemGrandchildren[currentSelectedGrandchild].value = false
                                }
                            }

                            menuItemChildren[currentSelectedChildItem].value = false
                        }
                    }

                    menuItems[currentSelectedItem].value = false
                }
            },

            getDrawerSelection () {
                if (!this.isAdmin) {
                    if (this.$route.path.includes('/dashboard')) {
                        this.changeMenuItemSelection(0)
                    } else if (this.$route.path.includes('/job') && !this.$route.path.includes('_titles')) {
                        this.changeMenuItemSelection(1)
                    } else if (this.$route.path.includes('/service')) {
                        this.changeMenuItemSelection(2)
                    } else if (this.$route.path.includes('/profile')) {
                        this.changeMenuItemSelection(3)
                    } else this.changeMenuItemSelection(null)
                } else {
                    if (this.$route.path.includes('/dashboard')) {
                        this.changeMenuItemSelection(0)
                    } else if (this.$route.path.includes('/industries')) {
                        this.changeMenuItemSelection(1)
                    } else if (this.$route.path.includes('/job_titles')) {
                        this.changeMenuItemSelection(2)
                    } else if (this.$route.path.includes('/news_feed')) {
                        this.changeMenuItemSelection(3)
                    } else this.changeMenuItemSelection(null)
                }
            }
        }
    }
</script>
<style scoped>
    >>> .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
        min-width: 0;
        margin-left: 0;
    }

    >>> .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
        min-width: 0;
        margin-left: 0;
    }

    >>> .v-list-group--sub-group.v-list-group--active .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
        display: none;
    }

    >>> .v-list-group--sub-group.v-list-group .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
        display: none;
    }

    >>> .v-progress-circular__underlay {
        stroke: #2E567A;
    }
</style>
