<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-03-24 11:25:57
 * @ Description: Component displaying the top nav bar and both side drawers in the dashboard section.
 -->

<template>
    <div
        class="no-print"
    >
        <!-- Desktop -->
        <v-app-bar
            v-if="$vuetify.breakpoint.mdAndUp"
            color="#F9FBFD"
            flat
            app
            height="108px"
        >
            <v-row
                justify="center"
            >
                <v-col
                    cols="12"
                    :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
                >
                    <v-row
                        align="center"
                    >
                        <v-col
                            class="pt-0 pr-0"
                        >
                            <!-- Toggle Left Drawer Button -->
                            <v-btn
                                icon
                                class="mr-2"
                                aria-label="menu"
                                @click="toggleMenuDrawer(!showMenuDrawer)"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiMenu }}
                                </v-icon>
                            </v-btn>
                            <!-- Toggle Left Drawer Button -->

                            <!-- Home Button -->
                            <v-btn
                                color="#222B45"
                                text
                                class="mr-2 no-uppercase os-17-r"
                                @click="$router.push(`/dashboard`)"
                            >
                                Home
                            </v-btn>
                            <!-- Home Button -->

                            <!-- Navigation Button -->
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#222B45"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mr-2 no-uppercase os-17-r"
                                    >
                                        Navigation
                                        <v-icon
                                            color="#C5CEE0"
                                        >
                                            {{ mdiMenuDown }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, index) in items"
                                        :key="index"
                                        class="os-16-r"
                                        @click="navigateToItemDashboard(item.title)"
                                    >
                                        <v-icon
                                            color="#2E567A"
                                            size="24"
                                            class="mr-1 mt-1"
                                        >
                                            {{ item.icon }}
                                        </v-icon>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- Navigation Button -->

                            <!-- My Info Button -->
                            <v-btn
                                color="#222B45"
                                text
                                class="mr-2 no-uppercase os-17-r"
                                @click="$router.push(`/profile`)"
                            >
                                My Info
                            </v-btn>
                            <!-- My Info Button -->
                        </v-col>

                        <v-col
                            class="text-right pt-0 pl-0"
                        >
                            <!-- Calendar Button -->
                            <v-btn
                                icon
                                class="mr-2"
                                aria-label="calendar"
                                @click="calendar"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiCalendar }}
                                </v-icon>
                            </v-btn>
                            <!-- Calendar Button -->

                            <!-- Toggle Notification Drawer Button -->
                            <v-btn
                                icon
                                class="mr-2"
                                aria-label="notifications"
                                @click="toggleMenuDrawerRight(!showMenuDrawerRight)"
                            >
                                <v-badge
                                    overlap
                                    :content="notificationCount"
                                    color="#FFA858"
                                    v-if="notificationCount > 0"
                                >
                                    <v-icon
                                        color="#2E567A"
                                    >
                                        {{ mdiBell }}
                                    </v-icon>
                                </v-badge>
                                <v-icon
                                    color="#2E567A"
                                    v-else
                                >
                                    {{ mdiBell }}
                                </v-icon>
                            </v-btn>
                            <!-- Toggle Notification Drawer Button -->

                            <!-- Avatar -->
                            <v-avatar
                                size="32"
                                class="mx-3"
                            >
                                <img
                                    :src="avatar ? avatar : '/general/FixerIndividualIcon.png'"
                                    alt="user"
                                >
                            </v-avatar>
                            <!-- Avatar -->

                            <!-- Menu Button -->
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#222B45"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mr-2 no-uppercase os-17-r"
                                    >
                                        <div :style="$vuetify.breakpoint.md ? 'max-width: 110px;' : 'max-width: 250px;'" class="text-truncate">{{ name }}</div>
                                        <v-icon
                                            color="#C5CEE0"
                                        >
                                            {{ mdiMenuDown }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-list
                                    class="os-16-r"
                                >
                                    <v-list-item>
                                        <v-list-item-title>{{ email }}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item
                                        class="os-16-sb"
                                        style="cursor: pointer;"
                                        @click="upgrade"
                                    >
                                        <v-list-item-title>SUBSCRIPTIONS</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        style="cursor: pointer;"
                                        @click="settings"
                                    >
                                        <v-list-item-title>Settings</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        style="cursor: pointer;"
                                        @click="logout"
                                    >
                                        <v-list-item-title>Log Out</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!-- Menu Button -->

                            <!-- Subscriptions Button -->
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="upgrade"
                            >
                                Subscriptions
                            </v-btn>
                            <!-- Subscriptions Button -->
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    cols="12"
                    class="pa-0"
                >
                    <v-divider
                        light
                    />
                </v-col>
            </v-row>
        </v-app-bar>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-app-bar
            v-else-if="$vuetify.breakpoint.smAndDown"
            color="white"
            app
            height="65px"
        >
            <v-row
                align="center"
                style="width: 100%; position: relative;"
            >
                <v-col
                    class="flex-grow-1 flex-shrink-0"
                >
                    <!-- Avatar Menu Button -->
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                                size="32"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                            >
                                <img
                                    :src="avatar ? avatar : '/general/FixerIndividualIcon.png'"
                                    alt="user"
                                >
                            </v-avatar>
                        </template>
                        <v-list
                            class="os-16-r"
                        >
                            <v-list-item>
                                <v-list-item-title>{{ email }}</v-list-item-title>
                            </v-list-item>
                            <v-divider />
                            <v-list-item
                                class="os-16-sb"
                                style="cursor: pointer;"
                                @click="upgrade"
                            >
                                <v-list-item-title>SUBSCRIPTIONS</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                style="cursor: pointer;"
                                @click="settings"
                            >
                                <v-list-item-title>Settings</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                style="cursor: pointer;"
                                @click="logout"
                            >
                                <v-list-item-title>Log Out</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <!-- Avatar Menu Button -->

                    <!-- Toggle Left Drawer Button -->
                    <v-btn
                        icon
                        class="mr-2"
                        aria-label="notifications"
                        @click="toggleMenuDrawerRight(!showMenuDrawerRight)"
                    >
                        <v-badge
                            overlap
                            :content="notificationCount"
                            color="#FFA858"
                            v-if="notificationCount > 0"
                        >
                            <v-icon
                                color="#2E567A"
                            >
                                {{ mdiBell }}
                            </v-icon>
                        </v-badge>
                        <v-icon
                            color="#2E567A"
                            v-else
                        >
                            {{ mdiBell }}
                        </v-icon>
                    </v-btn>
                    <!-- Toggle Left Drawer Button -->
                </v-col>

                <!-- Logo -->
                <v-img
                    src="/branding/FixerLogoBlue.png"
                    :width="$store.state.general.customBreakpoints.xxs ? '5rem' : '7rem'"
                    style="position: absolute; left: 50%; transform: translateX(-50%);"
                />
                <!-- Logo -->

                <!-- Toggle Notification Drawer Button -->
                <v-col
                    class="flex-grow-0 flex-shrink-1"
                >
                    <v-btn
                        icon
                        aria-label="menu"
                        @click="toggleMenuDrawer(!showMenuDrawer)"
                    >
                        <v-icon
                            color="#2E567A"
                        >
                            {{ mdiMenu }}
                        </v-icon>
                    </v-btn>
                </v-col>
                <!-- Toggle Notification Drawer Button -->
            </v-row>
        </v-app-bar>
        <!-- Mobile -->

        <!-- Left Drawer -->
        <navigation-dashboard-drawer-left-menu-component
            :showMenuDrawer="showMenuDrawer"
            :notificationCount="notificationCount"
            @toggleMenuDrawer="toggleMenuDrawer"
            @toggleMenuDrawerRight="toggleMenuDrawerRight"
        />
        <!-- Left Drawer -->

        <!-- Right Drawer -->
        <navigation-dashboard-drawer-right-notifications-component
            :showMenuDrawer="showMenuDrawerRight"
            @toggleMenuDrawer="toggleMenuDrawerRight"
            @notificationCountChanged="notificationCountChanged"
        />
        <!-- Right Drawer -->
    </div>
</template>
<script>
    import { mdiMagnify, mdiMenu, mdiMenuDown, mdiCalendar, mdiBell, mdiFlashOutline, mdiAccountSupervisorOutline/* , mdiPencilOutline, mdiLicense, */ } from '@mdi/js'
    import NavigationDashboardDrawerLeftMenuComponent from './drawer/NavigationDashboardDrawerLeftMenuComponent.vue'
    import NavigationDashboardDrawerRightNotificationsComponent from './drawer/NavigationDashboardDrawerRightNotificationsComponent.vue'

    export default {
        name: 'NavigationDashboardComponent',

        components: {
            NavigationDashboardDrawerLeftMenuComponent,
            NavigationDashboardDrawerRightNotificationsComponent
        },

        computed: {
            name () {
                if (this.$store.state.user.userContext === 'company') return this.$store.state.user.userData.company_name
                else return `${this.$store.state.user.userData.first_name} ${this.$store.state.user.userData.last_name}`
            },

            email () {
                return this.$store.state.user.userData.email
            },

            avatar () {
                return this.$store.state.user.userData.avatar
            }
        },

        data () {
            return {
                mdiMagnify,
                mdiMenu,
                mdiMenuDown,
                mdiCalendar,
                mdiBell,
                items: [
                    {
                        title: 'Job',
                        icon: mdiFlashOutline
                    },
                    {
                        title: 'Professional Service',
                        icon: mdiAccountSupervisorOutline
                    }
                ],
                showMenuDrawer: true,
                showMenuDrawerRight: false,
                notificationCount: 0
            }
        },

        methods: {
            toggleMenuDrawer (value) {
                this.showMenuDrawer = value
            },

            toggleMenuDrawerRight (value) {
                this.showMenuDrawerRight = value
            },

            navigateToItemDashboard (item) {
                if (item === 'Job') {
                    if (this.$route.path !== `/job/active`) this.$router.push(`/job/active`)
                } else if (item === 'Professional Service') {
                    if (this.$route.path !== '/service/provided/active') this.$router.push(`/service/provided/active`)
                }
            },

            upgrade () {
                if (this.$route.path !== `/profile/account`) this.$router.push(`/profile/account`)
            },

            settings () {
                if (this.$route.path !== `/profile`) this.$router.push(`/profile`)
            },

            logout () {
                this.$store.commit('user/resetUserData')
            },

            calendar () {
                if (this.$route.path !== `/calendar`) this.$router.push(`/calendar`)
            },

            notificationCountChanged (notificationCount) {
                this.notificationCount = notificationCount
            }
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
    }

    li span {
        display: block;
    }

    >>> .v-list-item__title {
        height: 25px;
    }
</style>