<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-13 17:28:42
 * @ Description: Fixer's dashboard index page.
 -->

<template>
    <div>
        <!-- Navigation -->
        <navigation-dashboard-component />
        <!-- Navigation -->

        <!-- Router -->
        <router-view />
        <!-- Router -->
    </div>
</template>
<script>
    import NavigationDashboardComponent from '@/components/navigation/dashboard/NavigationDashboardComponent.vue'

    export default {
        name: 'DashboardIndexPage',

        components: {
            NavigationDashboardComponent
        }
    }
</script>
<style>
    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 1s;
    }

    .fade-leave-active {
        transition: opacity 1s;
        opacity: 0;
    }
</style>
