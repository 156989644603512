<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-24 10:33:17
 * @ Description: Component displaying the right notification drawer in the dashboard section.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-navigation-drawer
            v-model="menuDrawer"
            disable-resize-watcher
            color="white"
            width="415"
            app
            right
            temporary
            :stateless="showReadAllNotificationsConfirmationOverlayBoolean || showDeleteAllNotificationsConfirmationOverlayBoolean"
            v-if="$vuetify.breakpoint.lgAndUp"
        >
            <div>
                <v-row
                    style="background-color: #2E567A; position: relative;"
                    align="center"
                    class="ma-0"
                >
                    <!-- Toggle Drawer Button -->
                    <v-btn
                        icon
                        style="position: absolute; right: 12px;"
                        @click="toggleMenuDrawer"
                    >
                        <v-icon
                            color="white"
                        >
                            {{ mdiChevronRight }}
                        </v-icon>
                    </v-btn>
                    <!-- Toggle Drawer Button -->

                    <!-- Notification Indicator -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                        style="color: white; height: 64px; display: flex; align-items: center; justify-content: center;"
                    >
                        <v-badge
                            overlap
                            :content="unreadNotificationsLength"
                            color="#FFA858"
                            v-if="unreadNotificationsLength > 0"
                        >
                            <v-icon
                                color="white"
                            >
                                {{ mdiBell }}
                            </v-icon>
                        </v-badge>
                        <v-icon
                            color="white"
                            v-else
                        >
                            {{ mdiBell }}
                        </v-icon>
                        <span class="ml-4">Notifications</span>
                    </v-col>
                    <!-- Notification Indicator -->
                </v-row>

                <!-- Notification Topics -->
                <v-row
                    class="ma-0 elevation-2"
                >
                    <v-col
                        cols="6"
                        style="height: 64px; display: flex; align-items: center; justify-content: center; position: relative; cursor: pointer;"
                        v-for="(item, index) in notificationTopics"
                        :key="index"
                        class="os-18-sb"
                        @click="changeNotificationTopicSelection(index)"
                    >
                        <div
                            style="position: absolute; bottom: 0; height: 4px; background-color: #FFA858; width: 100%;"
                            v-if="item.selected"
                        />
                        <span
                            class="mr-2"
                            :style="item.selected ? 'color: #2E567A;' : 'color: #8F9BB3;'"
                        >
                            {{ item.name }}
                        </span>
                        <v-badge
                            overlap
                            dot
                            color="#FFA858"
                            bottom
                            v-if="hasNotifications(item.contextID)"
                        >
                            <v-icon
                                color="#6E8AA3"
                                size="22"
                            >
                                {{ item.icon }}
                            </v-icon>
                        </v-badge>
                        <v-icon
                            color="#6E8AA3"
                            size="22"
                            v-else
                        >
                            {{ item.icon }}
                        </v-icon>
                    </v-col>
                </v-row>
                <!-- Notification Topics -->

                <!-- Bulk Action Buttons -->
                <v-row
                    class="mb-2"
                >
                    <v-col
                        cols="12"
                        class="text-right py-0"
                        style="height: 28px;"
                    >
                        <v-btn
                            text
                            small
                            class="no-uppercase rounded-lg os-13-sb mr-1"
                            color="#8F9BB3"
                            @click="confirmDeleteAll"
                            :disabled="notifications.length === 0"
                        >
                            Delete All
                        </v-btn>
                        <v-btn
                            text
                            small
                            class="no-uppercase rounded-lg os-13-sb"
                            color="#8F9BB3"
                            @click="confirmMarkAllAsRead"
                            :disabled="notifications.length === 0"
                        >
                            Mark All As Read
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- Bulk Action Buttons -->

                <!-- Notifications -->
                <div
                    style="max-height: calc(100vh - 176px); overflow-y: auto;"
                >
                    <v-row
                        v-for="(item, index) in filteredNotifications"
                        :key="index"
                        style="background-color: #F9FBFD; position: relative; cursor: pointer;"
                        class="ma-0 mb-1"
                        @click="notificationAction(item, $event)"
                    >
                        <div>
                            <v-icon
                                color="#FFA858"
                                size="14"
                                style="position: absolute; top: 11px; right: 36px;"
                                v-if="!item.notification_is_read"
                            >
                                {{ mdiCircle }}
                            </v-icon>
                            <v-btn
                                icon
                                style="position: absolute; top: 0px; right: 0px;"
                                @click="deleteNotifications([item])"
                                id="deleteButton"
                            >
                                <v-icon
                                    color="black"
                                    size="16"
                                    id="deleteButton"
                                >
                                    {{ mdiClose }}
                                </v-icon>
                            </v-btn>
                            <v-col
                                cols="12"
                                style="min-height: 98px; display: flex; align-items: center;"
                            >
                                <v-row>
                                    <v-col
                                        class="flex-shrink-1 flex-grow-0"
                                    >
                                        <v-avatar
                                            size="64"
                                        >
                                            <img
                                                :src="item.sender_avatar ? item.sender_avatar : '/general/FixerIndividualIcon.png'"
                                                alt="user"
                                            >
                                        </v-avatar>
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0"
                                    >
                                        <div
                                            class="os-16-sb"
                                        >
                                            {{ item.sender_name }}
                                        </div>
                                        <div
                                            class="os-12-r"
                                            style="color: #2E567A;"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                                size="16"
                                                v-if="Number(item.notification_type_id) === 3"
                                            >
                                                {{ mdiFlashOutline }}
                                            </v-icon>
                                            {{ item.notification_description }}:
                                            <br />
                                            <span style="color: #FFA858; margin-left: 18px;">{{ item.work_name }}</span>
                                            <br />
                                            <span style="color: #8F9BB3;">{{ item.notification_created_date }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                        <!-- <div
                            v-else
                        >
                            <v-icon
                                color="#FFA858"
                                size="14"
                                style="position: absolute; top: 11px; right: 36px;"
                            >
                                {{ mdiCircle }}
                            </v-icon>
                            <v-btn
                                icon
                                style="position: absolute; top: 0px; right: 0px;"
                            >
                                <v-icon
                                    color="black"
                                    size="16"
                                >
                                    {{ mdiClose }}
                                </v-icon>
                            </v-btn>
                            <v-col
                                cols="12"
                                style="min-height: 98px; display: flex; align-items: center;"
                            >
                                <v-row>
                                    <v-col
                                        class="flex-shrink-1 flex-grow-0"
                                    >
                                        <v-avatar
                                            size="64"
                                        >
                                            <img
                                                :src="item.user.avatar"
                                                alt="user"
                                            >
                                        </v-avatar>
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0"
                                    >
                                        <div
                                            class="os-16-sb"
                                        >
                                            {{ item.user.name }} {{ item.user.surname }}
                                        </div>
                                        <div
                                            class="os-12-r"
                                            style="color: #2E567A;"
                                        >
                                            1 New Message:
                                            <br />
                                            <span style="color: #FFA858;">{{ item.notification.item }}</span>
                                            <br />
                                            <span style="color: #8F9BB3;">{{ item.notification.date }}, {{ item.notification.time }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div> -->
                    </v-row>
                </div>
                <!-- Notifications -->
            </div>
        </v-navigation-drawer>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-navigation-drawer
            v-model="menuDrawer"
            disable-resize-watcher
            color="white"
            :width="$vuetify.breakpoint.smAndUp ? '50%' : '100%'"
            app
            right
            v-else-if="$vuetify.breakpoint.mdAndDown"
            :stateless="showReadAllNotificationsConfirmationOverlayBoolean || showDeleteAllNotificationsConfirmationOverlayBoolean"
        >
            <div>
                <v-row
                    style="background-color: #2E567A; position: relative;"
                    align="center"
                    class="ma-0"
                >
                    <!-- Toggle Menu Drawer Button -->
                    <v-btn
                        icon
                        style="position: absolute; right: 12px;"
                        @click="toggleMenuDrawer"
                    >
                        <v-icon
                            color="white"
                        >
                            {{ mdiChevronRight }}
                        </v-icon>
                    </v-btn>
                    <!-- Toggle Menu Drawer Button -->

                    <!-- Notification Indicator -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                        style="color: white; height: 64px; display: flex; align-items: center; justify-content: center;"
                    >
                        <v-badge
                            overlap
                            :content="unreadNotificationsLength"
                            color="#FFA858"
                            v-if="unreadNotificationsLength > 0"
                        >
                            <v-icon
                                color="white"
                            >
                                {{ mdiBell }}
                            </v-icon>
                        </v-badge>
                        <v-icon
                            color="white"
                            v-else
                        >
                            {{ mdiBell }}
                        </v-icon>
                        <span class="ml-4">Notifications</span>
                    </v-col>
                    <!-- Notification Indicator -->
                </v-row>

                <!-- Notification Topics -->
                <v-row
                    class="ma-0 elevation-2"
                >
                    <v-col
                        cols="6"
                        style="height: 64px; display: flex; align-items: center; justify-content: center; position: relative; cursor: pointer;"
                        v-for="(item, index) in notificationTopics"
                        :key="index"
                        class="os-18-sb"
                        @click="changeNotificationTopicSelection(index)"
                    >
                        <div
                            style="position: absolute; bottom: 0; height: 4px; background-color: #FFA858; width: 100%;"
                            v-if="item.selected"
                        />
                        <span
                            class="mr-2"
                            :style="item.selected ? 'color: #2E567A;' : 'color: #8F9BB3;'"
                        >
                            {{ item.name }}
                        </span>
                        <v-badge
                            overlap
                            dot
                            color="#FFA858"
                            bottom
                            v-if="hasNotifications(item.contextID)"
                        >
                            <v-icon
                                color="#6E8AA3"
                                size="22"
                            >
                                {{ item.icon }}
                            </v-icon>
                        </v-badge>
                        <v-icon
                            color="#6E8AA3"
                            size="22"
                            v-else
                        >
                            {{ item.icon }}
                        </v-icon>
                    </v-col>
                </v-row>
                <!-- Notification Topics -->

                <!-- Bulk Action Buttons -->
                <v-row
                    class="mb-2"
                >
                    <v-col
                        cols="12"
                        class="text-right py-0"
                        style="height: 28px;"
                    >
                        <v-btn
                            text
                            small
                            class="no-uppercase rounded-lg os-13-sb mr-1"
                            color="#8F9BB3"
                            @click="confirmDeleteAll"
                            :disabled="notifications.length === 0"
                        >
                            Delete All
                        </v-btn>
                        <v-btn
                            text
                            small
                            class="no-uppercase rounded-lg os-13-sb"
                            color="#8F9BB3"
                            @click="confirmMarkAllAsRead"
                            :disabled="notifications.length === 0"
                        >
                            Mark All As Read
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- Bulk Action Buttons -->

                <!-- Notifications -->
                <div
                    style="max-height: calc(100vh - 176px); overflow-y: auto;"
                >
                    <v-row
                        v-for="(item, index) in filteredNotifications"
                        :key="index"
                        style="background-color: #F9FBFD; position: relative; cursor: pointer;"
                        class="ma-0 mb-1"
                        @click="notificationAction(item, $event)"
                    >
                        <div>
                            <v-icon
                                color="#FFA858"
                                size="14"
                                style="position: absolute; top: 11px; right: 36px;"
                                v-if="!item.notification_is_read"
                            >
                                {{ mdiCircle }}
                            </v-icon>
                            <v-btn
                                icon
                                style="position: absolute; top: 0px; right: 0px;"
                                @click="deleteNotifications([item])"
                                id="deleteButton"
                            >
                                <v-icon
                                    color="black"
                                    size="16"
                                    id="deleteButton"
                                >
                                    {{ mdiClose }}
                                </v-icon>
                            </v-btn>
                            <v-col
                                cols="12"
                                style="min-height: 98px; display: flex; align-items: center;"
                            >
                                <v-row>
                                    <v-col
                                        class="flex-shrink-1 flex-grow-0"
                                    >
                                        <v-avatar
                                            size="64"
                                        >
                                            <img
                                                :src="item.sender_avatar ? item.sender_avatar : '/general/FixerIndividualIcon.png'"
                                                alt="user"
                                            >
                                        </v-avatar>
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0"
                                    >
                                        <div
                                            class="os-16-sb"
                                        >
                                            {{ item.sender_name }}
                                        </div>
                                        <div
                                            class="os-12-r"
                                            style="color: #2E567A;"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                                size="16"
                                                v-if="Number(item.notification_type_id) === 3"
                                            >
                                                {{ mdiFlashOutline }}
                                            </v-icon>
                                            {{ item.notification_description }}:
                                            <br />
                                            <span style="color: #FFA858; margin-left: 18px;">{{ item.work_name }}</span>
                                            <br />
                                            <span style="color: #8F9BB3;">{{ item.notification_created_date }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </v-row>
                </div>
                <!-- Notifications -->
            </div>
        </v-navigation-drawer>
        <!-- Mobile -->

        <!-- Delete All Notifications Overlay Component -->
        <navigation-dashboard-drawer-delete-all-notifications-confirmation-overlay-component
            :showDeleteAllNotificationsConfirmationOverlayBoolean="showDeleteAllNotificationsConfirmationOverlayBoolean"
            @showDeleteAllNotificationsConfirmationOverlay="showDeleteAllNotificationsConfirmationOverlay"
        />
        <!-- Delete All Notifications Overlay Component -->

        <!-- Mark All Notifications As Read Overlay Component -->
        <navigation-dashboard-drawer-read-all-notifications-confirmation-overlay-component
            :showReadAllNotificationsConfirmationOverlayBoolean="showReadAllNotificationsConfirmationOverlayBoolean"
            @showReadAllNotificationsConfirmationOverlay="showReadAllNotificationsConfirmationOverlay"
        />
        <!-- Mark All Notifications As Read Overlay Component -->
    </div>
</template>
<script>
    import { mdiCircle, mdiClose, mdiFlashOutline, mdiAccountSupervisorOutline, mdiPencilOutline, mdiLicense, mdiMessageProcessing, mdiBriefcase, mdiChevronRight, mdiBell } from '@mdi/js'
    import Vue from 'vue'
    import { GeneralNotificationController } from '@/logic/controller/index.controller.js'
    import NavigationDashboardDrawerDeleteAllNotificationsConfirmationOverlayComponent from '@/overlays/navigation/dashboard/drawer/NavigationDashboardDrawerDeleteAllNotificationsConfirmationOverlayComponent.vue'
    import NavigationDashboardDrawerReadAllNotificationsConfirmationOverlayComponent from '@/overlays/navigation/dashboard/drawer/NavigationDashboardDrawerReadAllNotificationsConfirmationOverlayComponent.vue'

    const moment = require('moment')

    export default {
        name: 'NavigationDashboardDrawerRightNotificationsComponent',

        components: {
            NavigationDashboardDrawerDeleteAllNotificationsConfirmationOverlayComponent,
            NavigationDashboardDrawerReadAllNotificationsConfirmationOverlayComponent
        },

        props: {
            showMenuDrawer: {
                type: Boolean,
                default: false,
                required: true
            }
        },

        watch: {
            showMenuDrawer: {
                handler (value) {
                    this.menuDrawer = value
                },
                immediate: true
            },

            menuDrawer: {
                handler (value) {
                    this.$emit('toggleMenuDrawer', value)
                }
            },

            '$store.state.socketIO.initialized': {
                async handler (value) {
                    if (value) {
                        const userID = this.$store.state.user.userData.id

                        Vue.prototype.$socketIO.on(`user/${userID}/notification`, async (notification) => {
                            this.addNotification(notification.message)
                        })
                    }
                },
                immediate: true
            },
            
            notifications: {
                handler () {
                    this.$emit('notificationCountChanged', this.unreadNotificationsLength)
                },
                immediate: true
            }
        },

        computed: {
            filteredNotifications () {
                let filteredItems

                if (this.selectedNotificationTopic === 'Activity') {
                    filteredItems = this.notifications.filter(function (e) {
                        return (Number(e.notification_context_id) === 1)
                    })
                } else {
                    filteredItems = this.notifications.filter(function (e) {
                        return (Number(e.notification_context_id) === 2)
                    })
                }

                return filteredItems
            },

            unreadNotificationsLength () {
                const filteredItems = this.notifications.filter(function (e) {
                    return (e.notification_is_read === 0)
                })

                return filteredItems.length
            }
        },

        data () {
            return {
                mdiCircle,
                mdiClose,
                mdiFlashOutline,
                mdiAccountSupervisorOutline,
                mdiPencilOutline,
                mdiLicense,
                mdiChevronRight,
                mdiBell,
                menuDrawer: false,
                selectedNotificationTopic: 'Activity',
                notificationTopics: [
                    {
                        name: 'Activity',
                        icon: mdiBriefcase,
                        selected: true,
                        contextID: 1
                    },
                    {
                        name: 'Messages',
                        icon: mdiMessageProcessing,
                        selected: false,
                        contextID: 2
                    }
                ],
                notifications: [],
                generalNotificationController: null,
                showReadAllNotificationsConfirmationOverlayBoolean: false,
                showDeleteAllNotificationsConfirmationOverlayBoolean: false
            }
        },

        beforeMount () {
            this.generalNotificationController = new GeneralNotificationController()
        },

        async mounted () {
            await this.getNotifications()

            navigator.serviceWorker.addEventListener('message', (data) => {
                // console.log(data)
            })
        },

        methods: {
            toggleMenuDrawer () {
                this.$emit('toggleMenuDrawer', false)
            },

            async getNotifications () {
                try {
                    const response = await this.generalNotificationController.retrieve()

                    if (response && response.length > 0) {
                        for (let index = response.length - 1; index >= 0; index--) {
                            const notification = response[index]
                            this.addNotification(notification)
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async markNotificationsAsRead (notifications) {
                try {
                    const response = await this.generalNotificationController.markAsRead(notifications)

                    this.notifications = []
                    
                    if (response && response.length > 0) {
                        for (let index = response.length - 1; index >= 0; index--) {
                            const notification = response[index]
                            this.addNotification(notification)
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async deleteNotifications (notifications) {
                try {
                    const response = await this.generalNotificationController.delete(notifications)

                    this.notifications = []
                    
                    if (response && response.length > 0) {
                        for (let index = response.length - 1; index >= 0; index--) {
                            const notification = response[index]
                            this.addNotification(notification)
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            changeNotificationTopicSelection (index) {
                const topicIndex = this.notificationTopics.findIndex(topicToFind => topicToFind.selected === true)
                if (topicIndex > -1) this.notificationTopics[topicIndex].selected = false

                this.notificationTopics[index].selected = true
                this.selectedNotificationTopic = this.notificationTopics[index].name
            },

            addNotification (notification) {
                if (notification.notification_description === '0 New Message/s') this.deleteNotifications([notification])
                else {
                    const dateTimeFormat = "YYYY-MM-DD HH:mm"
                    notification.notification_created_date = moment(new Date(notification.notification_created_date)).format(dateTimeFormat)

                    const removeTime = notification.notification_created_date.split(' ')[0]
                    const time = notification.notification_created_date.split(' ')[1]
                    const removeSeconds = time.split(':')
                    const convertDate = new Date(removeTime)
                    const splitConvertedDate = convertDate.toString().split(' ')
                    notification.notification_created_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3] + ', ' + removeSeconds[0] + ':' + removeSeconds[1]

                    const notificationIndex = this.notifications.findIndex(itemToFind => Number(itemToFind.notification_id) === notification.notification_id)
                    if (notificationIndex < 0 && Number(notification.receiver_id) === this.$store.state.user.userData.id) {
                        this.notifications.unshift(notification)
                    } else if (Number(notification.notification_context_id) === 2 && Number(notification.receiver_id) === this.$store.state.user.userData.id) {
                        this.notifications.splice(notificationIndex, 1)
                        this.notifications.unshift(notification)
                    }
                }
            },

            hasNotifications (contextID) {
                const notificationIndex = this.notifications.findIndex(itemToFind => Number(itemToFind.notification_context_id) === contextID && !itemToFind.notification_is_read)
                if (notificationIndex > -1) return true
                else return false
            },

            async notificationAction (notification, event) {
                if (event.target.id !== 'deleteButton' && event.target.nodeName !== 'svg') {
                    await this.markNotificationsAsRead([notification])

                    if (Number(notification.notification_context_id) === 1) {
                        // Activity
                        if (Number(notification.notification_type_id) === 3) {
                            // Job
                            switch (notification.notification_event_id) {
                            case 1:
                                // New post
                                if (this.$store.state.user.userContext === 'company') {/* console.log('New post company') */}
                                else if (this.$route.path !== `job/${notification.work_id}/view`) this.$router.push(`/job/${notification.work_id}/view`)
                                break
                            case 2:
                                // Applicant applied
                                if (Number(notification.work_status_id) === 2) {
                                    if (this.$store.state.user.userContext === 'company') if (this.$route.path !== `/job/${notification.work_id}/applicant/${notification.sender_id}`) this.$router.push(`/job/${notification.work_id}/applicant/${notification.sender_id}`)
                                    else {/* console.log('Applicant applied individual') */}
                                } else {
                                    this.$store.commit('setSnackbar', {
                                        text: 'This job is not in a recruiting status',
                                        show: true,
                                        color: '#069B34'
                                    })
                                }
                                break
                            case 3:
                                // Applicant declined
                                if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant declined company') */}
                                else if (this.$route.path !== '/job/search') this.$router.push('/job/search')
                                break
                            case 4:
                                // Applicant accepted
                                if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant accepted company') */}
                                else if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.work_applicant_id}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_applicant_id}`)
                                break
                            case 5:
                                // Updated
                                if (Number(notification.work_status_id) === 20 || Number(notification.work_status_id) === 21 || Number(notification.work_status_id) === 7) {
                                    // Reviewed or Completed
                                    if (this.$route.path !== '/profile/reviews') this.$router.push('/profile/reviews')
                                } else if (Number(notification.work_stage_id) === 2 || Number(notification.work_status_id) === 19) {
                                    // Pending or Cancelled During Recruitment
                                    if (this.$route.path !== `/job/${notification.work_id}/view`) this.$router.push(`/job/${notification.work_id}/view`)
                                } else if (Number(notification.work_stage_id) === 3 || Number(notification.work_status_id) === 6) {
                                    // Active or Cancelled
                                    if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.work_applicant_id}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_applicant_id}`)
                                }
                                break
                            case 6:
                                // Interview
                                if (this.$store.state.user.userContext === 'company') {/* console.log('Interview company') */}
                                else {
                                    if (this.$route.path !== 'calendar') this.$router.push({ path: '/calendar', query: { event_id: notification.notification_calendar_event_id }}).catch(() => true)
                                    else this.$router.replace({ query: { event_id: notification.notification_calendar_event_id }}).catch(() => true)
                                }
                                break
                            default:
                                break
                            }
                        } else if (Number(notification.notification_type_id) === 2) {
                            // Professional Service
                            switch (notification.notification_event_id) {
                            case 1:
                                // // New post
                                // if (this.$store.state.user.userContext === 'company') {/* console.log('New post company') */}
                                // else if (this.$route.path !== `job/${notification.work_id}/view`) this.$router.push(`/job/${notification.work_id}/view`)
                                break
                            case 2:
                                // Service requested
                                if (Number(notification.work_stage_id) === 2) {
                                    if (this.$route.path !== `/service/${notification.service_id}/requested/${notification.work_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                                } else {
                                    this.$store.commit('setSnackbar', {
                                        text: 'This professional service is not in a pending status',
                                        show: true,
                                        color: '#069B34'
                                    })
                                }
                                break
                            case 3:
                                // // Applicant declined
                                if (this.$route.path !== `/service/${notification.work_id}/requested/${notification.service_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                                // if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant declined company') */}
                                // else if (this.$route.path !== '/job/search') this.$router.push('/job/search')
                                break
                            case 4:
                                // // Applicant accepted
                                // if (this.$store.state.user.userContext === 'company') {/* console.log('Applicant accepted company') */}
                                // else if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.service}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_id}`)
                                break
                            case 5:
                                // Updated
                                // if (Number(notification.work_stage_id) === 2) {
                                if (this.$route.path !== `/service/${notification.work_id}/requested/${notification.service_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                                // } else {
                                //     this.$store.commit('setSnackbar', {
                                //         text: 'This professional service is not in a pending status',
                                //         show: true,
                                //         color: '#069B34'
                                //     })
                                // }
                                break
                            default:
                                break
                            }
                        }
                    } else if (Number(notification.notification_context_id) === 2) {
                        // Message
                        if (Number(notification.notification_type_id) === 3) {
                            // Job
                            switch (notification.notification_event_id) {
                            case 1:
                                break
                            case 2:
                                break
                            case 3:
                                break
                            case 4:
                                break
                            case 5:
                                // Updated
                                if (Number(notification.work_stage_id) === 3 || Number(notification.work_stage_id) === 4) {
                                    // Active or Completed or Cancelled
                                    if (this.$route.path !== `/job/${notification.work_id}/overview/${notification.work_applicant_id}`) this.$router.push(`/job/${notification.work_id}/overview/${notification.work_applicant_id}`)
                                } else if (Number(notification.work_stage_id) === 2) {
                                    // Pending
                                    if (Number(notification.work_owner_id) === notification.sender_id) {
                                        // Owner sent message
                                        if (this.$route.path !== `/job/${notification.work_id}/applicant/${notification.receiver_id}`) this.$router.push(`/job/${notification.work_id}/applicant/${notification.receiver_id}`)
                                    } else {
                                        // Applicant sent message
                                        if (this.$route.path !== `/job/${notification.work_id}/applicant/${notification.sender_id}`) this.$router.push(`/job/${notification.work_id}/applicant/${notification.sender_id}`)
                                    }
                                }
                                break
                            case 6:
                                break
                            default:
                                break
                            }
                        } else if (Number(notification.notification_type_id) === 2) {
                            // Service
                            switch (notification.notification_event_id) {
                            case 1:
                                break
                            case 2:
                                break
                            case 3:
                                break
                            case 4:
                                break
                            case 5:
                                // Updated
                                if (this.$route.path !== `/service/${notification.service_id}/requested/${notification.work_id}`) this.$router.push(`/service/${notification.service_id}/requested/${notification.work_id}`)
                                break
                            case 6:
                                break
                            default:
                                break
                            }
                        }
                    }
                }
            },

            confirmDeleteAll () {
                this.showDeleteAllNotificationsConfirmationOverlay(true)
            },

            async showDeleteAllNotificationsConfirmationOverlay (value, confirmDelete) {
                this.showDeleteAllNotificationsConfirmationOverlayBoolean = value

                if (confirmDelete) await this.deleteNotifications(this.notifications)
            },

            confirmMarkAllAsRead () {
                this.showReadAllNotificationsConfirmationOverlay(true)
            },

            async showReadAllNotificationsConfirmationOverlay (value, confirmRead) {
                this.showReadAllNotificationsConfirmationOverlayBoolean = value

                if (confirmRead) await this.markNotificationsAsRead(this.notifications)
            }
        }
    }
</script>
<style scoped>
    >>> .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
        min-width: 0;
        margin-left: 0;
    }

    >>> .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
        min-width: 0;
        margin-left: 0;
    }

    >>> .v-list-group--sub-group.v-list-group--active .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
        display: none;
    }

    >>> .v-list-group--sub-group.v-list-group .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
        display: none;
    }

    >>> .v-progress-circular__underlay {
        stroke: #2E567A;
    }
</style>
